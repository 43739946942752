<div class="container-fluid" style="width: 100%">
	<div class="row">
		<div class="col-md-4 col-xs-12 my-5">
			<div class="text-center">
				<img
					src="https://res.cloudinary.com/ducctr5hn/image/upload/w_300,h_90/v1564419163/assets/5_sbmmzb.png"
					alt=""
				/>
			</div>
			<div class="text-center" style="margin-left: 7.3rem">
				<a
					href="https://www.linkedin.com/in/mamta-kumar"
					target="_blank"
				>
					<img
						src="../../../../assets/images/linkedin.svg"
						alt=""
						style="width: 1.5rem; height: 1.5rem"
					/>
				</a>

				<a
					href="https://twitter.com/coboticsai"
					target="_blank"
					class="ml-4"
				>
					<img
						src="../../../../assets/images/twitter.svg"
						alt=""
						style="width: 1.5rem; height: 1.5rem"
					/>
				</a>
			</div>
		</div>
		<div
			class="col-md-8 text-center col-xs-12"
			style="background-color: #edf3fd"
		>
			<div class="footer-nav">
				<div class="container-fluid">
					<div class="row">
						<div class="col-xs-4 col-md-2 mb-2">
							<a
								class="nav-item1"
								routerLink="/home"
								routerLinkActive="active"
								>HOME</a
							>
						</div>
						<div class="col-xs-4 col-md-2 mb-2">
							<a
								class="nav-item1"
								routerLink="/services"
								routerLinkActive="active"
								>SERVICES</a
							>
						</div>
						<div class="col-md-2 col-xs-5 mb-2">
							<a
								class="nav-item1"
								routerLink="/aboutUs"
								routerLinkActive="active"
								>ABOUT US</a
							>
						</div>
						<div class="col-md-2 col-xs-4 mb-2">
							<a
								class="nav-item1"
								routerLink="/contact"
								routerLinkActive="active"
								>CONTACT</a
							>
						</div>
						<div class="col-md-2 col-xs-4 px-0 mx-0">
							<a
								class="nav-item1"
								routerLink="/products"
								routerLinkActive="active"
								>PRODUCTS</a
							>
						</div>
						<div class="col-md-2 col-xs-4 px-0 mx-0 pb-3">
							<a
								class="nav-item1"
								routerLink="/legal"
								routerLinkActive="active"
								>LEGAL</a
							>
						</div>
					</div>
				</div>
			</div>
			<div class="pt-5">
				<div class="row">
					<div
						class="col-md-5 col-xs-12 text-md-right text-xs-center"
					>
						<p>
							<span>
								<img
									src="../../../../assets/images/copyright.svg"
									alt=""
									class="copy"
							/></span>
							<span class="year">2022</span>
							<span class="powered">POWERED BY</span>
						</p>
					</div>
					<div
						class="col-md-5 col-xs-12 mx-0 px-0 text-md-left text-xs-center"
					>
						<p class="company">COBOTICS BUSINESS SERVICE LLC</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
