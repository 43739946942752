<nav class="navbar navbar-expand-lg navbar-light border-0 mt-2 " style="background-color: transparent; ">
	<a class="navbar-brand ml-2" routerLink="/home">
		<img src="https://res.cloudinary.com/ducctr5hn/image/upload/w_240,h_70/v1564419163/assets/5_sbmmzb.png"
			 alt="">
	</a>
	<button class="navbar-toggler" type="button"
			data-toggle="collapse"
			data-target="#navbarNavAltMarkup"
			aria-controls="navbarNavAltMarkup"
			aria-expanded="false"
			aria-label="Toggle navigation">
		<span class="navbar-toggler-icon"></span>
	</button>
	<div class="collapse navbar-collapse flex-grow-0" id="navbarNavAltMarkup">
		<div class="navbar-nav" >
			<a class="nav-item nav-link  " routerLink="/home" routerLinkActive="active">HOME </a>
			<a class="nav-item nav-link  " routerLink="/services" routerLinkActive="active">SERVICES</a>
			<a class="nav-item nav-link " routerLink="/aboutUs" routerLinkActive="active">ABOUT US</a>
			<a class="nav-item nav-link" routerLink="/contact" routerLinkActive="active">CONTACT</a>
			<a class="nav-item nav-link " routerLink="/products" routerLinkActive="active">PRODUCTS</a>
		</div>
	</div>
</nav>
