<div class="container-fluid mt-2 mt-md-5">
	<div class="text-center heading-margin" >
		<p class="heading ">
			WE OFFER VARIOUS OPTIONS FOR YOU TO CHOOSE FROM
			AND WORK WITH US DEPENDING ON YOUR INTERNAL IT
			INFRASTRUCTURE AND AVAILABLE SKILL SET
		</p>
	</div>
	<div class="row d-flex align-items-center">
		<div class="col-md-6 col-xs-12 text-left  text-xs-center ml-2 ml-md-5 ">
			<p class="main-header mb-3">
				EXPLORATORY DATA ANALYSIS
				AND HYPOTHESIS TESTING </p>
			<p class="main-para my-4">
				Exploratory Data Analysis and Hypothesis Testing
				allows to reach conclusions that are more justifiable
				and are true opportunities to increase profitability.
				We apply machine learning techniques to dig into
				large amount of data to discover patterns that were
				not immediately apparent.</p>
		</div>
		<div class="col-md-5  col-xs-12 text-center mb-3 my-0 py-0">
			<img class="image-1"
				src="https://res.cloudinary.com/ducctr5hn/image/upload/w_630,h_740/v1563097983/assets/8_ptdzzc.png"
				alt="">
		</div>
	</div>
	<div class="row d-flex align-items-center my-4">
		<div class="col-md-5  col-xs-12 text-center mb-3 my-0 py-0 ml-0 ml-md-4 order-last order-md-first">
			<img class="image-2"
				src="https://res.cloudinary.com/ducctr5hn/image/upload/w_630,h_740/v1563097983/assets/9_rlhqko.png"
				alt="">
		</div>
		<div class="col-md-6 col-xs-12 text-center text-md-left  text-xs-center second-column  order-first order-md-large">
			<p class="main-header mb-3">
				MACHINE LEARNING
				MODELING AND VALIDATIONS</p>
			<p class="main-para my-4">
				With the latest advancements in Machine Learning, especially with Deep Learning, companies can take
				advantage of adaptive and cognitive decision aid solutions that are trained using their multi-enterprise
				data to precisely describe current and future state of their businesses.</p>
		</div>
	</div>

	<div class="row d-flex align-items-center mt-5 " style="margin-bottom: 6rem">
		<div class="col-md-6 col-xs-12 text-left   ml-2 ml-md-5 ">
			<p class="main-header mb-3 text-xs-center">
				ARTIFICIAL INTELLIGENCE
				AND MACHINE LEARNING AS
				A SERVICE </p>
			<p class="main-para my-4 ml-2">
				As your business and industry evolves or adapts to changes, so will be your data and the insights that
				you will need to stay ahead of the curve. We offer continuous support to manage your fluctuating
				business environment by maintaining and fine tuning the model as a service.</p>
		</div>
		<div class="col-md-5  col-xs-12 text-center mb-3 my-0 py-0">
			<img class="image-3"
				src="https://res.cloudinary.com/ducctr5hn/image/upload/w_630,h_740/v1563097983/assets/10_uskwwi.png"
				alt="">
		</div>
	</div>
</div>
