<!--The content below is only a placeholder and can be replaced.-->
<app-main-navbar></app-main-navbar>

<router-outlet></router-outlet>
<app-main-footer></app-main-footer>
<div class="container-fluid">
	<div class="row">
		<div class="col-xs-12">
			<cookie-law learnMore="/#/cookie"  name="CoboticsCookie" expiration="7"></cookie-law>
		</div>
	</div>
</div>
