<div class="container text-center mt-4 ">
	<div class="text-center  contain mb-4 ml-0 pl-0 ">
		<p class="sub-title margin-title"
		   (click)="onClickLogistics()"
		   [ngClass]="logistics ? 'sub-title-selected': 'sub-title'">LOGISTICS & TRANSPORTATION</p>
		<p class="sub-title"
		   (click)="onClickOccupational()"
		   [ngClass]="occupation ? 'sub-title-selected': 'sub-title'"
		>OCCUPATIONAL SAFETY AND INCIDENT MANAGEMENT</p>
		<p class="sub-title ml-1"
		   (click)="onClickSourcing()"
		   [ngClass]="sourcing ? 'sub-title-selected': 'sub-title'"
		> SOURCING AND COSTING</p>
	</div>

	<div class="" *ngIf="logistics">
		<p class="main-header mt-4 mb-5">TOUCHSTONE</p>
		<section class="mb-5">

			<iframe [width]="width" [height]="height" src="https://www.youtube.com/embed/hTUJyIt2j-g?rel=0"
					frameborder="0"></iframe>
		</section>

		<div [ngStyle]="{'width.px': width}" [ngClass]="(width == 1000)?'ml-5':'my-class2'">
			<p class="main-para pb-5"> &nbsp; Today we can easily monitor global economic indicators and
				market indices but the
				ability to
				quantify and time their combined impact on current and future state of the business is the real
				challenge.
				<br> <br>

				&nbsp;The Cobotics-AI Research and Development team has been ideating and prototyping a new platform
				called
				TOUCHSTONE. A solution which combines your enterprise data with external market influencers to
				personalize
				and
				quantity their current and future impact on your enterprise portfolio and partner strategy.
			</p>
		</div>
	</div>
	<div class="" *ngIf="occupation">
		<p class="main-header mt-4 mb-5">DEFENDER</p>
		<section class="mb-5">

			<iframe [width]="width" [height]="height" src="https://www.youtube.com/embed/63w7gcDEgvc?rel=0"
					frameborder="0"></iframe>
		</section>

		<div [ngStyle]="{'width.px': width}" [ngClass]="(width == 1000)?'ml-5':'my-class2'">
			<p class="main-para pb-5"> &nbsp; Introducing DEFENDER, an AI system that has been proven to identify and
				reduce extremely rare
				Occupational Illness and Injury events by 27-34% in field operations. DEFENDER is an early warning
				system with the ability to predict I&I incidents well in advance with 93% precision.
				<br> <br>
				&nbsp;DEFENDER combines multidimensional data specific to: the setting, nature & type of an assignment;
				training, experience & personal behavior of an employee assigned to perform the assignment; and lastly
				where & for whom the assignment is performed. These combined features are studied in-depth by an AI
				engine to generate early warnings and actionable insights against the risk prone assignments and
				employees.
			</p>
		</div>
	</div>
	<div class="" *ngIf="sourcing">
		<p class="main-header mt-4 mb-5">APPRAISER</p>
		<section class="mb-5">

			<iframe [width]="width" [height]="height" src="https://www.youtube.com/embed/cf2qTyEGhv8?rel=0"
					frameborder="0"></iframe>
		</section>

		<div [ngStyle]="{'width.px': width}" [ngClass]="(width == 1000)?'ml-5':'my-class2'">
			<p class="main-para pb-5"> &nbsp;Powered by AI, Appraiser application exploits machine’s ability to analyze
				quotes and rank parters using complex multi-level, multi-criteria decision making algorithms. Rank your
				carrier partners and their quoted rates in depth eliminating any unintentional cognitive bias and
				false-consensus effect. In one click of a button, predict, simulate & optimize your spend portfolio,
				strengthen your partner strategy and create greater value for your products and services.
			</p>
		</div>
	</div>
</div>
