<div class="container">
	<p class="header text-center mt-5">PRIVACY POLICY</p>
	<p class="policy">
Welcome!  Thank you for visiting a website owned and operated by Cobotics Business Services Inc. (together with its subsidiaries and affiliated companies, “Cobotics,” “we,” “our,” or “us”). 
		Cobotics is founded to help companies accelerate their growth and be part of AI-powered global economy in an affordable, reliable and secure IT framework. We recognize the importance of protecting your personal information and are committed to processing it responsibly and in compliance with applicable data protection laws.
This Privacy Notice applies to personal information that we obtain or collect from or about individuals who use our website www.Coboticsllc.com, www.cobotics-ai.com and our other related websites, tools, applications or mobile apps (altogether, the “Site”).  We may provide additional or more specific information on the collection, use, or other processing of personal information obtained through use of a particular website or related to a specific product or service.  This Privacy Notice is designed to inform you about how we collect, use, share, and otherwise process your personal information, and the choices you can make about collection, use, and sharing of information you affirmatively provide to us, or that we passively collect, when you use the Site.
When you access or use the Site, you acknowledge that you have read this Privacy Notice and understand its contents.  Your use of the Site is subject to this Privacy Notice and any applicable Terms and Conditions.

Personal Information Cobotics Collects
Cobotics collects personal information about you in a variety of ways.  The following are examples of the types of personal information that may be collected.  The specific kinds of personal information collected will depend on the Site you use:
<br>•	Registration and Account Information:
<br>•	You can browse the Site without providing us with direct identifiers like your name, email address, phone number, mobile telephone number, or mailing address.
<br>•	To register for an account or establish a profile on the Site, you will need to give us personal information such as your:
<br>•	Contact information (such as name, email address, mailing address, and phone number)
<br>•	Account log-in credentials (e.g., username and password).
<br>•	Information to Respond to Your Requests: You may also give us your personal information to sign up for our newsletters and other communications. &nbsp;&nbsp;&nbsp; You can exercise certain choices concerning how and when you will receive such newsletters and other communications by following instructions available on the Site.
<br>•	Information You Post on the Site: You may provide us with information regarding your opinions and reviews of products.
<br>•	Information from Other Sources: The information we collect may be combined with information from outside records (e.g., demographic information, navigation information, marketing information, and/or additional contact information) that we have acquired in accordance with the law.

		Technical Site Usage Information: When you visit the Site, we collect usage information sent to us by your computer, mobile device, or other access device that tells us how you are using the Site (“usage information”).  We may also collect password information from you when you log in, as well as computer and connection information.  During some of your visits to the Site, we may use software tools to measure and collect session information, including page response times, download errors, time spent on certain pages, and page interaction information.  For more information about the use of cookies and similar technologies on the Site, please review our Cookies and Tracking Technologies Policy (hyperlink to https://coboticsllc.com//cookies-statement
	<br>•	Children’s Information:  Cobotics does not intend that any portion of the Site will be accessed or used by children under the age of 13.  We do not knowingly collect personal information via our websites, applications, services, or tools from users in this age group.  If you are a parent or guardian of a child under the age of 13 and believe we have collected information from your child in error or have questions or concerns about our practices relating to children, please notify us at admin@coboticsllc.com and we will promptly respond.

How and Why Cobotics Uses Personal Information
We use the personal information that we collect for various purposes.  Purposes for which we use all other personal information you affirmatively provide to us when you use the Site, or that we passively collect when you use the Site, include to:
	<br>•	Operate the Site:  We use personal information to:  (i) respond to your requests; (ii) provide customer service (e.g., to resolve disputes, problems with your account/profile or the Site); (iii) personalize your experience by providing content on the Site; (iv) improve the Site; (v) give you information about your account; and (vi) provide you with a safe, efficient, and customized experience.
<br>•	Provide Products and Services:  We use personal information to:  (i) provide you with our products, services, and resources; (ii) create and manage your account(s); (iii) allow you to order and purchase products and services;.
	<br>•	Communicate with You:  We use information collected from you to: contact you; to maintain your account(s) with the Site; to provide you with other requested services (as described at the time when we collect the information from you); and to send you other communications that you have requested, to answer your questions or respond to your requests, or contact you for other purposes authorized by law.
	<br>•	Prevent Fraud:  We use information collected from the Site to prevent, detect, and investigate fraud, security breaches, potentially prohibited or illegal activities, and enforce any applicable Terms and Conditions and otherwise manage our business.
Also, when you access the Site on a mobile device, we may use the information collected for any purpose set forth in this Privacy Notice. 

When Do We Share Personal Information?
We respect the importance of privacy.  We do not sell your personal information to marketing companies outside of Cobotics, unless we have your consent.  Generally, we may share your information, in order to facilitate the provision of our products, services, and communications with customers, including:
	<br>•	Service Providers and Other Third Parties:  We will disclose personal information to service providers under contract to help with our business operations
	<br>•	Legal and Law Enforcement:  We may share personal information with law enforcement, governmental agencies, or authorized third-parties, in response to a verified request relating to a criminal investigation or alleged illegal activity or any other activity that may expose us, you, or any other Cobotics user to legal liability.  In this regard, we may share your information with third parties in response to a subpoena, other legal process and/or as permitted by law.  In such events, we will only disclose information relevant and necessary to the investigation or inquiry, such as name, city, state, zip code, telephone number, email address, user ID history, IP address, fraud complaints and anything else we may deem relevant to the investigation.
	<br>•	Change of Control or Reorganization:  If we decide to sell, buy, merge or otherwise reorganize businesses in some countries, such a transaction may involve the disclosure of personal information to prospective or actual purchasers, or the receipt of such information from sellers.  It is our practice to seek appropriate protection for personal information in these types of transactions.
	<br>•	Companies in the Mobile App Industry:  If you access the Site through a mobile device, we may also share your information with carriers, operating systems, and platforms.
	<br>•	Other Entities with Your Consent:  We will share personal information with other third parties to whom you explicitly ask us to send your information (or about whom you are otherwise explicitly notified and consent to when using a specific service).  On the Site, you may have opportunities to express interest in or register for other services.  If you do, we will provide information about you to those third parties, or parties working on their behalf, to implement your request.

Legal Basis for Processing Personal Information
If the processing of your personal information is subject to the EU General Data Protection Regulation (GDPR), whenever we use or share personal information, we ensure that we have identified a legal basis under which such processing may occur.  Where we reference that we use your personal information in connection with a request, order, transaction, or agreement (or preparing for the same), or to provide you with services that you requested (such as use of the Site), we do this because it is necessary for the performance of an agreement with you.
Further, to the extent GDPR is implicated, where we reference that we use personal information in relation to improvement, or development of our products or services, for reasons of safety and security, or to satisfy regulatory requirements, other than in connection with your agreement or request, we do this on the basis of our (or a third party’s) legitimate interests or with your consent, as appropriate.

International Data Transfers
Cobotics is headquartered in the United States, and we have operations, entities, and service providers in the United States and throughout the world.  Some countries have implemented transfer restrictions for personal information, in connection with which Cobotics takes various measures, including the Standard Contractual Clauses authorized under the EU Data Protection Directive 95/46/EC.
The personal information that we collect pursuant to this Privacy Notice may be transferred to countries other than your own country for storage or processing, and these countries may offer different levels of privacy protection than apply in your own country.

Retention of Personal Information
Cobotics will retain personal information collected pursuant to this Privacy Notice for as long is necessary to fulfill the purposes for which it is processed (as outlined in this Privacy Notice).  We will also retain personal information collected pursuant to this Privacy Notice in order to comply with legal and regulatory obligations, handle disputes, and to establish, exercise or defend against legal claims in the countries where we do business.
How to Contact Us
If you have a question related to this Privacy Statement, please contact us by emailing us at admin@Coboticsllc.com.  Your message will be forwarded to the appropriate member of Cobotics Data Privacy Team, such as Data Protection Officers or members of their teams.
For the purposes of the GDPR , the controller of your personal information is Cobotics Business Services LLC 8920 Colonial Pl, Duluth, GA, United States. 

Data Subject Rights
You can request to access, update, or correct your personal information.  
Additional Rights Where Personal Information Is Subject to the GDPR:
	•	If the processing of your personal information is subject to the GDPR, and your personal information is processed based on legitimate interests, you have the right to object to the processing on grounds relating to your specific situation.
	•	If the processing of your personal information is subject to the GDPR, you also have certain rights to request to have your personal information deleted, request that processing of your personal information be restricted, and to ask for portability of your personal information.
	•	You can exercise these rights (including making these requests) by emailing us at admin@Coboticsllc.com. Please put the statement “GPDR Privacy Rights” in the body of your request, as well as your name, country or region, and enough information to allow us to respond to your request.

Notice to California Residents:
	•	Requests for Listing of Certain Disclosures.  Residents of the State of California may request a list of all third parties to which the Site has disclosed certain personal information (as defined by California law) during the preceding year for those third-parties’ direct marketing purposes.  If you are a California resident and want such a list, please contact us at admin@Coboticsllc.com.  For all requests, you must put the statement “Your California Privacy Rights” in the body of your request, as well as your name, street address, city, state, and zip code.  In the body of your request, please provide enough information for us to determine if this applies to you.  You need to attest to the fact that you are a California resident and provide a current California address for our response.  Please note that we will not accept requests via the telephone, mail, or by facsimile, and we are not responsible for notices that are not labeled or sent properly, or that do not have complete information.
	•	How We Respond to Do Not Track Signals for California Residents:  California Business & Professions Code Section 22575(b) (as amended effective January 1, 2014) provides that California residents are entitled to know how Cobotics responds to “Do Not Track” browser settings.  Cobotics does not currently take actions to respond to Do Not Track signals because a uniform technological standard has not yet been developed.  We continue to review new technologies and may adopt a standard once one is created.

Right to Make a Complaint
In the event you consider our processing of your personal information not to be compliant with the applicable data protection laws, you can lodge a complaint by emailing us at admin@Coboticsllc.com.
If the processing of your personal information is subject to the GDPR, you may also contact the relevant Data Protection Authorities in the EU to lodge a complaint:  http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm.

Third-Party Websites
The Site may contain links to other websites for your convenience and information.  These websites may be operated by companies not affiliated with Cobotics and we are not responsible for the content, privacy practices, or use of any websites that are linked to or from the Site.

Notification Regarding Updates
From time to time, we may update this Privacy Notice.  If we make material changes to this Privacy Notice, we will provide notice through our Site, or by other means, to provide you the opportunity to review the changes.  If you object to any changes, you may close your account.  You acknowledge that your continued use of the Site after we publish or send a notice about our changes to this Privacy Notice means that the collection, and use of your personal information is subject to the updated Privacy Notice.
This Privacy Notice is effective as of May 25, 2018.

	</p>
</div>
