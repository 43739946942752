<div class="container-fluid mt-5 ">
	<div class="row d-flex align-items-center">
		<div class="col-md-5 col-xs-12 text-left  ml-3  text-xs-center ml-md-5  mb-xs-2">
			<p class="main-header mb-3">LET’S BUILD AN &nbsp; <br>
				<span class="highlight-violet  ">AI</span> POWERED FUTURE &nbsp; <br> TOGETHER </p>
			<p class="main-para my-3"> In Cobotics-AI, We firmly believe that Companies can now
				benefit from AI without heavy investments in resource and infrastructure </p>
			<p class="learn-more pointer mb-3 mt-5" [routerLink]="['/products']">LEARN MORE
				<span>
					<img src="../../../assets/images/arrow.svg" alt="" class="pointer arrow">
				</span>
			</p>
		</div>
		<div class="col-md-6 col-xs-12 text-center mb-3 my-0 py-md-0  py-xs-5 ">
			<img src="https://res.cloudinary.com/ducctr5hn/image/upload/w_600,h_490/v1563097983/assets/1_ee2tzh.png"
				 class="image-1"
				 alt="">
		</div>
	</div>
	<div class="row d-flex align-items-center my-5">
		<div class="col-md-6 col-xs-12 text-xs-center mb-3 my-0 py-0  order-last order-md-first ">
			<img src="https://res.cloudinary.com/ducctr5hn/image/upload/w_700,h_600/v1563097983/assets/2_k38oj2.png"
				 class="image-2"

				 alt="">
		</div>
		<div class="col-md-5 col-xs-12 text-left  text-xs-center  ml-md-5  ml-3  order-first order-md-large">
			<p class="main-header mb-3 ">VALUABLE BUSINESS&nbsp; <br>
				<span class="highlight-yellow ">INSIGHTS</span> FOR YOU </p>
			<p class="main-para my-3"> We generate and protect business insights that you will
				need to stay ahead of the curve and stand out amongst
				your competitors </p>
			<p pageScroll href="#learnmore" class="learn-more pointer mb-3 mt-5"
			>LEARN MORE
				<span>
					<a pageScroll href="#learnmore">
						<img src="../../../assets/images/arrow.svg" alt="" class="pointer arrow">
					</a>
				</span>
			</p>
		</div>
	</div>

	<div class="row d-flex align-items-center my-5">
		<div class="col-md-5 col-xs-12 text-left  text-xs-center  ml-3  ml-md-5 ">
			<p class="main-header mb-3">WE MINE
				<span class="highlight-blue">GOLD</span></p>
			<p class="main-para my-3">We are data miners, who train algorithms to mine gold out
				of your data. We specialize in business artificial intelligence </p>
			<p class="learn-more pointer mb-3 mt-5" [routerLink]="['/services']"
			   routerLinkActive="$router_link-active$">LEARN MORE
				<span>
					<img src="../../../assets/images/arrow.svg" alt="" class="pointer arrow">
				</span>
			</p>
		</div>
		<div class="col-md-6 col-xs-12 text-center mb-3 my-0 py-0">
			<img src="https://res.cloudinary.com/ducctr5hn/image/upload/w_700,h_600/v1563097983/assets/3_bqasca.png"
				 class="image-3"

				 alt="">
		</div>
	</div>
	<div class="row text-center" style="background-color: #FEBA6A">
		<div class="col-xs-12 col-md-6 offset-md-3 my-4">
			<p class="quest ">WHY <span class="highlight-red">COBOTICS</span>-<span class="highlight-violet">AI</span> ?
			</p>
			<p class="quest-para">COBOTICS-AI is founded to help companies accelerate
				their growth and be part of AI-powered global economy
				in an affordable, reliable and secure IT framework </p>
		</div>
	</div>


	<div class="text-center my-5 " id="learnmore">
		<img  class="block-diagram" src="https://res.cloudinary.com/ducctr5hn/image/upload/w_1080,h_800/v1563007198/assets/4_exovy0.png"
			 alt="">
	</div>


	<div class="text-center my-5 pt-5  row">
		<div class="col-xs-12 col-md-10 offset-md-1">
			<p class="ans">“WE DO NOT BUILD MACHINES THAT THINK LIKE HUMANS,
				WE DESIGN MACHINES THAT CAN HELP HUMANS THINK BETTER”</p>
		</div>
	</div>
</div>
