<div class="container-fluid">
	<div class="row d-flex  my-4">
		<div class="col-md-4  col-xs-12 text-center mb-3 my-0 py-0 ">
			<img class="image"
				 src="https://res.cloudinary.com/ducctr5hn/image/upload/w_400,h_550/v1563007209/assets/Group_1698_jojrfb.png"
				 alt="">
		</div>
		<div class="col-md-8 col-xs-12 text-left  text-xs-center ">
			<p class="main-header mb-1">
				MAMTA KUMAR</p>
			<p class="main-subheader mb-3">
				FOUNDER</p>
			<p class="main-para my-4">
				We believe in the power of data that we generate, change and consume every day and how it can be used to transform businesses and uncover hidden opportunities. Artificial intelligence and machine learning solutions can understand, predict and optimize businesses by studying their internal enterprise data and combining it with the external influencers.				<br><br>
				Cobotics, a cognitive decision aid solutions & services provider company, was founded to help businesses accelerate their growth and be part of an AI-powered global economy in an affordable, reliable and secure IT framework. Our customers benefit from AI based solutions & services without any heavy investments in resources and infrastructure.</p>
			<div class="text-center pt-5">
				<p class="footer"> JOIN FORCES WITH COBOTICS TODAY TO BE PART OF AN
					AI ENABLED TOMORROW</p>
			</div>

		</div>
	</div>
	<div class="row d-flex pt-5 mb-4">
		<div class="col-md-4  col-xs-12 text-center mb-3 my-0 py-0 ">
			<img
				class="image"
				src="https://res.cloudinary.com/ducctr5hn/image/upload/w_400,h_550/v1563007209/assets/7_rwrifm.png"
				alt="">
		</div>
		<div class="col-md-8 col-xs-12 text-left  text-xs-center ">
			<p class="main-header mb-1">
				DR. ARTI DESHPANDE</p>
			<p class="main-subheader mb-3">
				RESEARCH SCIENTIST</p>
			<p class="main-para my-4">
				Arti is a research scientist at Cobotics Business Services, LLC. specialized in data mining techniques
				and applications
				development. She has 18+ years of extensive research work and has several publications in the area of
				advanced data
				analytics and machine learning. She has developed a combined use of association rules and clustering
				methods using
				domain driven mining. She has proposed framework, algorithms, evaluation methods and decision making
				rules that
				can be understood and acted upon by the business users to make decisions</p>
			<p class="contri text-center">CONTRIBUTIONS</p>
			<div class="text-center">
				<div class="row shadow-sm pdf d-none d-md-inline-flex" style="height: 13.5rem;">
					<div class="col-1 pointer d-flex align-items-center ">
						<img [src]="decrementUrl" alt="" style="width: 4rem; height: 2rem"
							 (click)="decrementPdf()">
					</div>
					<div class="col-10 d-flex ">
						<div class="justify-content-center" >
							<p class="heading1 mt-2">"{{ selectedPdf.heading | uppercase}}" </p>
							<p class="details "> {{ selectedPdf.details | uppercase}}</p>
						</div>
					</div>
					<div class="col-1 pointer d-flex align-items-center">
						<img src="../../../assets/images/rightarrow.svg" alt="" style="width: 4rem; height: 2rem"
							 (click)="incrementPdf()">
					</div>
					<div class=" view ">
						<a class="pointer " [href]="selectedPdf.link" target="_blank"
						   style="color : #50539A; letter-spacing: 0.3rem; font-size: 1.3rem">VIEW</a>
					</div>
				</div>
				<div class="shadow-sm  d-block d-md-none">

					<div class="text-center">
						<img [src]="decrementUrl" alt=""
							 style="width: 2rem; height: 1rem"
							 (click)="decrementPdf()">
						<img src="../../../assets/images/rightarrow.svg" alt=""
							 style="width: 2rem; height: 1rem"
							 (click)="incrementPdf()">
					</div>
					<div class="row  pdf" style="height: 13.5rem;">

						<div class="col-12 d-flex ">
							<div class="justify-content-center">
								<p class="heading1 mt-2">"{{ selectedPdf.heading | uppercase}}" </p>
								<p class="details"> {{ selectedPdf.details | uppercase}}</p>
							</div>
						</div>
						<div class=" view ">
							<a class="pointer " [href]="selectedPdf.link" target="_blank"
							   style="color : #50539A; letter-spacing: 0.3rem; font-size: 1.3rem">VIEW</a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div class="row d-flex pt-5 mb-5">
		<div class="col-md-4  col-xs-12 text-center mb-3 my-0 py-0 ">
			<img
				class="image"
				src="https://res.cloudinary.com/ducctr5hn/image/upload/w_400,h_550/v1563007209/assets/Group_1696_aa371n.png"
				alt="">
		</div>
		<div class="col-md-8 col-xs-12 text-left  text-xs-center ">
			<p class="main-header mb-1">
				BHANU VALLABHANENI</p>
			<p class="main-subheader mb-3">
				INFORMATION SECURITY & COMPLIANCE ADVISOR</p>
			<p class="main-para my-4">
				Bhanu Vallabhaneni is the Information Security & Compliance Advisor at Cobotics Business Services LLC.
				He
				specializes in Information Security, Risk and Compliance. He has managed information security and
				compliance for
				Fortune 500 companies across multiple geographies. In his advisory role, Bhanu oversees information
				security and
				regulatory compliance (SOX, HiPAA, GDPR) to various standards for the organization.</p>
			<div class="text-center">

			</div>

		</div>
	</div>
</div>
