<div class="container">
	<p class="header text-center mt-5"> COBOTICS COOKIES & TRACKING TECHNOLOGIES POLICY
	</p>

	<p class="policy mb-5">
		Cobotics Business Services LLC. (“Cobotics”), its subsidiaries and affiliated companies (collectively,
		“Cobotics,” “we,” “our,” or “us”) maintain our website www.coboticsllc.com, www.cobotics-ai.com and our other
		related web sites, tools, applications or mobile apps (collectively, the “Site”).  We use a variety of
		technologies to learn more about how people use the Site.  You can find out more about these technologies and
		how to control them in the information below.  This policy forms part of the Cobotics XYZ Privacy Policy.
		COOKIES & OTHER TRACKING TECHNOLOGIES & HOW WE USE THEM:  Like many companies, we use cookies and other tracking
		technologies on our Site (referred to together from this point forward as “cookies”, unless otherwise stated)
		including HTTP cookies, HTML5 and Flash local storage, web beacons/GIFs, embedded scripts and e-tags/cache
		browsers as defined below.We may use cookies for a variety of purposes and to enhance your online experience,
		for example, by remembering your log-in status and viewing preferences from a previous use of our Site, for when
		you later return to the Site. <br>
		We use both session and persistent tracking technologies.  Tracking technologies (e.g., cookies) can either be
		persistent (i.e., they remain on your computer until you delete them) or temporary (i.e., they last only until
		you close your browser). <br>
		We also use first and third-party cookies.  First party cookies are used and controlled by us to provide
		services on the Site. Third-party cookies are controlled by third-parties.
		In particular, our Site uses the following categories of cookies:
		Strictly Necessary Cookies: These are cookies which are needed to provide services and features you have
		specifically requested.  We may use cookies and tracking technologies required to prevent fraudulent activity,
		improve security (both not applicable under German law), for system administration and/or allow you to make use
		of shoppingcart functionality.  We are not required to obtain your consent to cookies that are strictly
		necessary. <br>
		Analytics and PerformanceRelated Cookies:  We may use cookies to assess the performance of our Site, including
		as part of our analytic practices to improve the content offered through the Site.  These cookies may be used to
		prevent fraudulent activity and improve security.
		FunctionalityRelated Cookies:  We may use cookies to tell us, for example, whether you have visited the Site
		before or if you are a new visitor and to help us identify the features in which you may have the greatest
		interest. <br><br>
		 
		• YOUR COOKIE CHOICES & HOW TO OPT-OUT:  You have the choice whether to accept the use of cookies and we have
		explained how you can exercise your rights, below. Most browsers are initially set up to accept HTTP Cookies.
		 The “help” feature of the menu bar on most browsers will tell you how to stop accepting new cookies, how to
		receive notification of new cookies and how to disable existing cookies.  For more information about HTTP
		cookies and how to disable them, you can consult the information
		at http://www.networkadvertising.org/choices/ and http://www.allaboutcookies.org/manage-cookies/.
		 <br>
		Controlling the HTML5 local storage on your browser depends on which browser you use.  For more information
		regarding your specific browser please consult the browser’s website (often in the Help section).
		<br>

		• COOKIES AND TRACKING TECHNOLOGIES USED ON THE COBOTICS SITE:  The following table sets out details of the
		individual cookies we use and the purposes for which we use them: <br>
		We provide the chart below as a courtesy for all users outside of Germany, but we are not obligated to maintain
		or update it.  We are not responsible for third-party sites and their privacy practices as it relates to
		opt-outs from tracking activities.  The following third-parties that collect information from you on our Site
		have given us notice that you may obtain information on their policies and practices, and in some instances
		opt-out of certain of their activities, as follows: <br>

	</p>
</div>
